import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/firestore";
import "firebase/storage";

var firebaseConfig = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTHDOMAIN,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
const devUrl = false ? "10.0.0.234" : "10.1.10.194";
process.env.NODE_ENV === "development" && firebase.functions().useEmulator(devUrl, 5001);

// AUTH
export const signIn = (email, password) => firebase.auth().signInWithEmailAndPassword(email, password);

export const auth = firebase.auth();

// FUNCTIONS
export const getUserDoc = (id) =>
	firebase
		.functions()
		.httpsCallable("getUserDoc")(id)
		.then(({ data }) => data);

export const setAttributes = (data) => firebase.functions().httpsCallable("setAttributes")(data);

export const verifyOrderNumber = (orderNumber, email) => firebase.functions().httpsCallable("verifyOrderNumber")({ orderNumber, email });

export const searchForOrderByEmail = (email) => firebase.functions().httpsCallable("searchForOrderByEmail")(email);

export const createAffiliate = (data) => firebase.functions().httpsCallable("createAffiliate")(data);

export const createAmbassador = (email) => firebase.functions().httpsCallable("createAmbassador")(email);

export const manuallyCreateAmbassador = (doc) =>
	firebase
		.functions()
		.httpsCallable("manuallyCreateAmbassador")(doc)
		.then(({ data }) => data);

export const getCustomTokenByUid = (uid) => firebase.functions().httpsCallable("getCustomTokenByUid")(uid);

export const setPassword = (pass) =>
	firebase
		.auth()
		.currentUser.updatePassword(pass)
		.catch((e) => {
			if (e.code === "auth/requires-recent-login") {
				const user = firebase.auth().currentUser;
				getCustomTokenByUid(user.uid).then((token) => {
					firebase
						.auth()
						.signInWithCustomToken(token.data)
						.then((u) => {
							setPassword(pass);
						});
				});
			}
		});

export const uploadImages = async ({ id, igHandle = null }, files = []) => {
	var storageRef = firebase.storage().ref();

	return Promise.all(
		files.map(async (f) => {
			const doc = {
				resolution: f.resolution,
				submitter: { handle: igHandle, id },
				size: f.file.size,
				timestamp: new Date().toJSON(),
			};
			let suffix = 1;
			let imageRef = storageRef.child(`/${igHandle}/${f.file.name}`); // TODO -- WHAT IF IT ALREADY EXISTS?
			while (suffix) {
				const url = await imageRef.getDownloadURL().catch(() => null);
				if (url) {
					imageRef = storageRef.child(`/${igHandle}/${f.file.name}(${suffix})`); // TODO -- WHAT IF IT ALREADY EXISTS?
				} else {
					break;
				}
				suffix++;
			}
			await imageRef.put(f.file).then(async (snap) => {
				doc.fullPath = snap.ref.fullPath;
				doc.hasAgreedToTerms = true;
				doc.url = await snap.ref.getDownloadURL(); // doesnt work if upload isnt finished -- it just hangs
			});
			const colRef = firebase.firestore().collection("ambassadors").doc(id).collection("submissions"); // TODO -- WHY IS THIS HANGING
			await colRef.get();
			const docRef = await colRef.add(doc).catch(console.error);
			return { ...doc, id: docRef.id };
		})
	);
};

export const setPhotoAsPosted = (doc, status = "POSTED") => {
	return doc.ref.update({ status });
};

export const getSubmissions = async (date, cursor = null) => {
	const from = new Date(date);
	const to = new Date(date);
	to.setDate(to.getDate() + 1);

	return firebase
		.firestore()
		.collectionGroup("submissions")
		.orderBy("timestamp", "desc")
		.where("timestamp", ">=", from.toISOString())
		.where("timestamp", "<", to.toISOString())
		.startAfter(cursor || to.toISOString())
		.limit(20)
		.get()
		.then((d) => d.docs.map((dd) => ({ ...dd.data(), id: dd.id, ref: dd.ref })));
};

export const getUserSubmissions = async (id) => {
	if (id) {
		const col = firebase.firestore().collection("ambassadors").doc(id).collection("submissions");
		return col
			.orderBy("timestamp", "desc")
			.get()
			.then((d) => d.docs.map((dd) => ({ ...dd.data(), id: dd.id })));
	}
};

export const deleteSubmission = async (ambassadorId, submission) => {
	await firebase
		.storage()
		.ref()
		.child(submission.fullPath)
		.delete()
		.catch(() => null);
	await firebase.firestore().collection("ambassadors").doc(ambassadorId).collection("submissions").doc(submission.id).delete();
};

export const searchForAmbassador = async (field, value) => {
	return firebase
		.firestore()
		.collection("ambassadors")
		.where(field, "==", value)
		.get()
		.then(({ docs }) =>
			docs.map((d) => {
				const data = d.data();
				return {
					...data,
					id: d.id,
					fullName: `${data.firstName || ""} ${data.lastName || ""}`,
				};
			})
		);
};

export const getAmbassadors = (createdAt = Infinity) => {
	return firebase
		.firestore()
		.collection("ambassadors")
		.orderBy("createdAt", "desc")
		.startAfter(createdAt)
		.limit(100)
		.get()
		.then((d) => {
			return d.docs.map((dd) => {
				const data = dd.data();
				return {
					...data,
					id: dd.id,
					fullName: `${data.firstName || ""} ${data.lastName || ""}`,
				};
			});
		});
};

export const setScopes = (uid, scopes) => firebase.functions().httpsCallable("setScopes")({ uid, scopes });

export const migrate = (page) => firebase.functions().httpsCallable("migrate")(page);

export const deleteAmbassadors = (ambassadors = []) => firebase.functions().httpsCallable("deleteAmbassadors")(ambassadors);

export const getAffiliate = (id) =>
	firebase
		.functions()
		.httpsCallable("getAffiliate")(id)
		.then(({ data }) => data);

export const editAffiliate = (id, data) => firebase.functions().httpsCallable("editAffiliate")({ id, data });

export const changeAffiliateCode = (affiliate_id, affiliate_code, oldTrigger, newTrigger) =>
	firebase.functions().httpsCallable("changeAffiliateCode")({ affiliate_id, affiliate_code, oldTrigger, newTrigger });

export const getConversions = (affiliate_id) => firebase.functions().httpsCallable("getConversions")(affiliate_id);

export const getUser = (id) =>
	firebase
		.functions()
		.httpsCallable("getUser")(id)
		.then((d) => d.data);

export const getUserByEmail = (email) =>
	firebase
		.functions()
		.httpsCallable("pullUserObj")(email)
		.then((d) => d.data);

export const editAmbassador = (data) =>
	firebase
		.functions()
		.httpsCallable("editAmbassador")(data)
		.then((d) => d.data);

export const resetPass = (email) => firebase.auth().sendPasswordResetEmail(email);

export const schedulePhotos = async (photos, date) => {
	for (const photo of photos) {
		await photo.ref.update({ scheduledFor: date, status: "SCHEDULED" });
	}
};

export const reportPhotos = async (photos, reason) => {
	for (const photo of photos) {
		await photo.ref.update({ status: "DENIED", reason });
	}
};

export const getScheduledPhotos = async () => {
	return firebase
		.firestore()
		.collectionGroup("submissions")
		.where("scheduledFor", "!=", null)
		.get()
		.then((r) => {
			return r.docs.map((d) => ({ ...d.data(), id: d.id, ref: d.ref }));
		});
};

export const removeFromSchedule = async (items = []) => {
	for (const item of items) {
		await firebase.firestore().collectionGroup("submissions").doc(item.id).update({ scheduledFor: null, status: "APPROVED" });
	}
};
