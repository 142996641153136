import { Grid, IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import moment from 'moment';
import { useState } from "react";
import { BiInfoCircle } from "react-icons/bi";



export default function CustomTextField({ id, label, formik, type = "text", icon, required, info = false, onChange = (v) => v, ...rest }) {
  const [open, setOpen] = useState(false);
  let value = formik.values[id];

  if (type === 'date') {
    value = moment(value).format("yyyy-MM-DD");
  }

  return (
    <Grid item {...rest}>
      <Tooltip open={open} title={info || ""} placement="bottom" arrow style={{ whiteSpace: 'break-spaces' }} >
        <TextField
          InputProps={{
            startAdornment: <InputAdornment position='start' sx={{ color: '#2F3C4F' }} >{icon}</InputAdornment>,
            endAdornment: info && (
              <InputAdornment position='end' sx={{ color: '#2F3C4F' }} >
                <IconButton size="small" onClick={(e) => { e.preventDefault(); setOpen(true) }}>
                  <BiInfoCircle />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onBlur={() => setOpen(false)}
          onMouseLeave={() => setOpen(false)}
          type={type}
          fullWidth
          id={id}
          name={id}
          label={label}
          value={value}
          onChange={e => {
            e.target.value = onChange(e.target.value);
            formik.handleChange(e)
          }}
          error={formik.touched[id] && !!formik.errors[id]}
          helperText={formik.touched[id] && formik.errors[id]}
        />
      </Tooltip>
    </Grid >
  )

}

