import { List, ListItem, ListItemText, Card, CardHeader, Divider, ListItemIcon } from "@mui/material";
import { useAuth } from "../../../AuthProvider";
import * as Icons from "react-icons/all";

export default function PayoutView() {
	const { userInfo } = useAuth();

	const total = userInfo.refersion?.conversions.reduce((p, c) => p + +c.commission_total, 0);
	return (
		<Card sx={{ m: 1 }}>
			<CardHeader title="Your Payouts" />
			<Divider />
			<List sx={{ width: "100%" }}>
				{userInfo.refersion?.conversions.map((p) => (
					<ListItem key={p.id} divider>
						<ListItemIcon>{p.payment_status}</ListItemIcon>
						<ListItemText primary={"$" + p.commission_total} secondary={new Date(p.created * 1000).toLocaleDateString()} />
					</ListItem>
				))}
				{!userInfo.refersion?.conversions.length ? (
					<ListItem>
						<ListItemText primary="No Payouts yet!" primaryTypographyProps={{ textAlign: "center" }} />
					</ListItem>
				) : (
					<ListItem>
						<ListItemText primaryTypographyProps={{ textAlign: "right" }} primary={"TOTAL: $" + total.toFixed(2)} />
					</ListItem>
				)}
			</List>
		</Card>
	);
}
