import { Alert, Button, Card, CardContent, CardHeader, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, Stack, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../../AuthProvider";
import { LoadingBackDrop, TextField } from "../../../components/general";
import { deleteSubmission, getUserSubmissions, uploadImages } from "../../../firebase";
import ImageComponent from "material-ui-image";
import { useNavigate } from "react-router-dom";
import * as Icons from "react-icons/all";

const MAX_SUBMISSIONS = 20;

export default function PhotoSubmissionView() {
	const [loading, setLoading] = useState(true);
	const [submissions, setSubmissions] = useState([]);
	const [pendingSubmissions, setPendingSubmissions] = useState([]);
	const [preview, setPreview] = useState();
	const [needHandleOpen, setNeedHandleOpen] = useState(false);
	const [openSubmissionDialog, setOpenSubmissionDialog] = useState(false);
	const [check, setCheck] = useState(false);
	const [igHandle, setIGHandle] = useState("");
	const fileInput = useRef();
	const { userInfo, updateUser } = useAuth();
	const nav = useNavigate();
	const isMobile = useMediaQuery((t) => t.breakpoints.down("sm"));

	useEffect(() => {
		if (userInfo.igHandle) {
			getUserSubmissions(userInfo.id).then(setSubmissions).finally(setLoading);
		} else {
			setLoading(false);
			setNeedHandleOpen(true);
			setCheck(false);
		}
	}, [userInfo.igHandle, userInfo.id]);

	const updateHandle = () => {
		updateUser({ igHandle })
			.then(() => {
				setNeedHandleOpen(false);
			})
			.finally(setLoading);
	};

	const handleSelection = ({ target }) => {
		const files = [...target.files].map((f) => ({ file: f }));
		fileInput.current.value = "";
		Promise.all(
			files.map(
				(f) =>
					new Promise((resolve) => {
						const img = new Image();
						img.src = URL.createObjectURL(f.file);
						f.url = img.src;
						img.onload = (e) => {
							f.resolution = `${img.naturalWidth}x${img.naturalHeight}`;
							resolve(f);
						};
					})
			)
		).then((uploads) => {
			setPendingSubmissions((s) => {
				const all = [...uploads, ...s];
				const set = [...new Set(all.map((f) => f.file.name))];

				return set.map((i) => all.find((ii) => ii.file.name === i));
			});
		});
	};

	const handleUpload = () => {
		setLoading(true);
		setOpenSubmissionDialog(false);
		uploadImages(userInfo, pendingSubmissions)
			.then((pendings) => {
				setSubmissions((s) => [...pendings, ...s]);
				setPendingSubmissions([]);
			})
			.catch(console.error)
			.finally(() => {
				setLoading(false);
				setCheck(false);
			});
	};

	const handleRemoveFromPending = (item) => (e) => {
		setPendingSubmissions((p) => p.filter((pp) => item !== pp));
	};

	const handleRemoveSubmission = (submission) => () => {
		if (window.confirm("Are you sure you want to delete this submission?")) {
			setLoading(true);
			deleteSubmission(userInfo.id, submission)
				.then(() => {
					setSubmissions((s) => s.filter((ss) => ss !== submission));
					setPreview();
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};
	const totalSubmissions = pendingSubmissions.length + submissions.length;

	return (
		<Container maxWidth="lg" sx={{ py: 2 }}>
			<Stack spacing={2}>
				<Card>
					<CardContent>
						<Stack>
							<Stack direction="row" justifyContent="end" spacing={2}>
								<Button
									disabled={totalSubmissions >= MAX_SUBMISSIONS}
									onClick={() => {
										fileInput.current.click();
										if (!pendingSubmissions.length) {
											setCheck(false);
										}
									}}
									variant="outlined"
								>
									Add Photos
								</Button>
								{!!pendingSubmissions.length && (
									<Button disabled={totalSubmissions > MAX_SUBMISSIONS || !check} onClick={handleUpload} variant="contained">
										Upload
									</Button>
								)}
							</Stack>
							{!!pendingSubmissions.length && (
								<Stack direction="row" justifyContent="end">
									<FormControlLabel
										sx={{ mr: 0 }}
										control={<Checkbox sx={{ pointerEvents: "auto" }} checked={!!check} onChange={() => setCheck(!check)} />}
										label={
											<>
												I agree to the{" "}
												<a href="#/" onClick={() => setOpenSubmissionDialog(true)}>
													terms and conditions
												</a>
												.
											</>
										}
									/>
								</Stack>
							)}
						</Stack>
					</CardContent>
					{totalSubmissions > MAX_SUBMISSIONS && (
						<Alert severity="error">
							Your account is limited to {MAX_SUBMISSIONS} submissions total. Remove at least {totalSubmissions - MAX_SUBMISSIONS} submission(s).
						</Alert>
					)}
					<CardContent>
						<ImageList cols={isMobile ? 2 : 5}>
							{pendingSubmissions.map((p, i) => (
								<ImageListItem key={i}>
									<ImageComponent src={p.url} style={{ objectFit: "cover" }} />
									<ImageListItemBar
										sx={{ bgcolor: "transparent" }}
										position="top"
										actionIcon={
											<IconButton sx={{ bgcolor: "white", opacity: 0.5, m: 1 }} onClick={handleRemoveFromPending(p)}>
												<Icons.BiTrash />
											</IconButton>
										}
									/>
								</ImageListItem>
							))}
						</ImageList>
					</CardContent>
				</Card>

				<Card>
					<CardHeader subheader={submissions.length + "/" + MAX_SUBMISSIONS} title="Your Submissions" />
					<CardContent>
						<ImageList cols={isMobile ? 2 : 5}>
							{submissions.map((p, i) => (
								<ImageListItem key={i} onClick={() => setPreview(p)}>
									<ImageComponent src={p.url} style={{ objectFit: "cover" }} />
								</ImageListItem>
							))}
						</ImageList>
					</CardContent>
				</Card>
			</Stack>
			<input max={MAX_SUBMISSIONS - pendingSubmissions.length - submissions.length} type="file" accept="image/*" multiple style={{ display: "none" }} onChange={handleSelection} ref={fileInput} />

			{/* IMAGE PREVIEW */}
			<Dialog open={!!preview} onClose={() => setPreview()} fullWidth>
				{preview && (
					<Stack spacing={2}>
						<ImageComponent src={preview?.url} style={{ objectFit: "cover" }} alt="preview" />
						<Button variant="contained" fullWidth onClick={handleRemoveSubmission(preview)}>
							Remove
						</Button>
					</Stack>
				)}
			</Dialog>

			{/* NEED HANDLE DIALOG */}
			<Dialog open={needHandleOpen} fullWidth>
				<DialogContent>
					<center>We want to tag you in your photos!</center>
					<br />
					<TextField value={igHandle} onTextChange={(t) => setIGHandle(t.toLowerCase())} placeholder="Instagram handle" />
				</DialogContent>
				<DialogActions>
					<Grid container justifyContent="space-evenly">
						<Grid item>
							<Button variant="outlined" onClick={() => nav("/dashboard")}>
								Go back
							</Button>
						</Grid>
						<Grid item>
							<Button variant="outlined" disabled={!igHandle} onClick={updateHandle}>
								Update
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>

			<Dialog open={openSubmissionDialog} onClose={() => setOpenSubmissionDialog(false)}>
				<DialogTitle variant="h4">Photo Release Form</DialogTitle>
				<DialogContent>
					By submitting photos, permission and consent is granted to Pack Leashes LLC for the use of the photographs submitted. These photos may be used under any legal condition, including but not limited to: publicity, copyright purposes, illustration, advertising, and web content. By submitting the photographs, you are representing that you have rightful ownership or permission to allow Pack Leashes LLC unlimited use of the photographs submitted. If you do not have rightful ownership or
					permission to grant this unlimited use to Pack Leashes, you hereby indemnify and hold harmless Pack Leashes LLC against any and all claims from the use of the photographs.
					<br />
					<br />
					<Typography variant="h4" mb={1}>
						Payment
					</Typography>
					There shall be no payment for this release.
					<br />
					<br />
					<Typography variant="h4" mb={1}>
						Royalties
					</Typography>
					No royalty, fee, or other compensation shall become payable to the party submitting the photos by reason of such use.
					<br />
					<br />
					<Typography variant="h4" mb={1}>
						Revocation
					</Typography>
					The rights granted to Pack Leashes may never be revoked.
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenSubmissionDialog(false)} variant="contained">
						Close
					</Button>
				</DialogActions>
			</Dialog>

			<LoadingBackDrop open={!!loading} />
		</Container>
	);
}
