import { createTheme, colors } from '@mui/material';
import shadows from './shadows';
import typography from './typography';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';

const theme = createTheme({
  palette: {
    background: {
      paper: colors.common.white
    },
    primary: {
      contrastText: '#ffffff',
      main: '#5664d2',
    },
    text: {
      primary: '#172b4d',
      secondary: '#6b778c'
    },
  },
  shadows,
  typography
});



export default function ThemeProvider({ children }) {

  return (
    <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
  )
};

export { default as GlobalStyles } from "./GlobalStyles";