import { Grid, Container, Box, Alert } from '@mui/material';
import { PromoCode, CurrentPayout, CodeDialog, Conversions } from '../../../components/dashboard';
import { useEffect, useState } from 'react';
import * as Icons from 'react-icons/all';
import { useNavigate } from 'react-router';
import { useAuth } from '../../../AuthProvider';




export default function DashboardView() {
  const [warnings, setWarnings] = useState([]);
  const nav = useNavigate();
  const { userInfo, updateUser } = useAuth();
  const [isCodeDialogOpen, setCodeDialogOpen] = useState(!userInfo.refersion?.conversion_triggers?.[0]);



  useEffect(() => {
    const w = [];
    if (!userInfo.refersion_id) w.push({ m: "There was an issue loading your account! Please refresh the page or reach out to us at bark@packleashes.com." });
    if (userInfo.refersion && !userInfo.refersion?.paypal_email) w.push({ m: "We're missing your payout info!", l: "/dashboard/account" });
    if (!userInfo.refersion && userInfo.refersion?.conversion_triggers?.[0]) w.push({ m: "Set your exclusive Friends and Family code!", l: "/dashboard/account" });
    if (!userInfo.photoCount) w.push({ m: "Upload your model photos to get featured on our IG!", l: "/dashboard/photo-submission" });
    setWarnings(w);
  }, [userInfo])





  return (
    <Box sx={{ minHeight: '100%', py: 1 }}>
      <Container maxWidth={false}>
        <Grid container spacing={3}>

          {warnings[0] && (
            <Grid item xs={12}>
              <Alert sx={styles.alert} severity="warning" variant="filled" action={warnings[0]?.l && <Icons.FaExternalLinkAlt />} onClick={() => warnings[0]?.l && nav(warnings[0]?.l)} >
                {warnings[0]?.m}
              </Alert>
            </Grid>
          )}

          {/*<Grid item xs={12} md={4} lg={6}>
            <Tier />
          </Grid>*/}


          <Grid item xs={12} md={4} lg={6}>
            <PromoCode {...{ userInfo, setCodeDialogOpen, error: !userInfo.refersion_id }} />
          </Grid>



          <Grid item xs={12} md={4} lg={6}>
            <CurrentPayout {...{ userInfo }} />
          </Grid>

          <Grid item xs={12}>
            <Conversions {...{ userInfo }} />
          </Grid>



        </Grid>

      </Container >
      <CodeDialog {...{ userInfo, updateUser, isCodeDialogOpen: isCodeDialogOpen && userInfo.refersion_id, setCodeDialogOpen }} />
    </Box>
  )
}


const styles = {
  alert: {
    cursor: 'pointer',
  }
}



