import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@mui/material';

const MainLayoutRoot = experimentalStyled('div')(
  ({ theme }) => ({
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  })
);
const MainLayout = () => (
  <MainLayoutRoot>
    <Outlet />
  </MainLayoutRoot>
);

export default MainLayout;
