import { Card, Hidden } from "@mui/material";
import Image from "material-ui-image";




export default function Banner() {

  return (
    <Hidden mdUp>
      <Card>
        <Image aspectRatio={3} cover imageStyle={{ objectFit: 'fill' }} src="/mobileBanner.png" />
      </Card>
    </Hidden>
  )
}