import { Box, Card as MuiCard, CardHeader, Skeleton } from "@mui/material";
import { useRef } from "react";




export default function Card({
  title = false,
  children,
  isLoading = false,
  error = false,
  titleSx = {}
}) {
  const cardRef = useRef();

  return (
    <>
      {isLoading ? (
        <Skeleton height={100} />
      ) : (
        <MuiCard ref={cardRef} sx={{ position: 'relative' }}>
          {title && <CardHeader title={title} sx={titleSx} />}
          {children}
        </MuiCard>
      )}
      {error && (
        <Card>
          <Box>
            ERROR
          </Box>
        </Card>
      )}
    </>
  )
}