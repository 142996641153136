import React, { useState } from 'react';
import { Avatar, Button, colors, Container, Typography, Card, CardContent, Stack } from '@mui/material';
import { LoadingBackDrop, TextField } from '../../../components/general';
import { LockOutlined } from '@material-ui/icons';
import firebase from 'firebase/app';
import "firebase/auth";




const styles = {
  paper: {
    marginTop: 8 * 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Poppins'
  },
  avatar: {
    margin: 4,
    backgroundColor: colors.blue[400],
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    mt: 4,

  },
  submit: {
    margin: "3px 0 2px",
    fontFamily: 'Poppins',
    backgroundColor: colors.blue[400]
  },
};



export default function ForgotView() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSent, setSent] = useState(false);



  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    firebase.auth().sendPasswordResetEmail(email, { url: 'https://thepack.packleashes.com/login', }).then(() => setSent(true)).catch(e => console.error(e)).finally(setLoading)
  }


  return (
    <Container component="main" maxWidth="xs" sx={{ height: '100%' }} >
      <Stack justifyContent='center' sx={{ height: '100%' }}>
        <Card>
          <CardContent>
            <Stack spacing={1} alignItems='center'>
              <img src="https://cdn.shopify.com/s/files/1/1649/7215/files/PACK_LOGO_FINAL_JUSTTYPE_TM_3fe8f11d-7e48-405b-a6fe-1829b34e0b2c.png?v=1613784681" alt="logo" style={{ height: "4.4rem", paddingTop: 15, paddingBottom: 15, objectFit: 'contain' }} />

              <Avatar style={styles.avatar}>
                <LockOutlined />
              </Avatar>

              <Typography component="h1" variant="h5" fontFamily='Poppins'>
                Password Reset
              </Typography>

              {isSent && (
                <Typography component="h1" variant="caption" textAlign='center' fontFamily='Poppins'>
                  If there is an account with this email you will receive a password reset in the next few minutes.
                </Typography>
              )}

              <form style={styles.form} noValidate onSubmit={handleSubmit} >
                <TextField margin="normal" align='left' required id="email" label="Email Address" name="email" autoComplete="email" autoFocus value={email} onTextChange={setEmail} disabled={loading} />

                <Button type="submit" fullWidth variant="contained" color='primary' style={styles.submit} disabled={!email || loading || isSent}>
                  {isSent ? "reset link sent!" : "Send reset link"}
                </Button>
              </form>
            </Stack>
          </CardContent>
        </Card>
      </Stack>


      <LoadingBackDrop open={loading} />
    </Container>
  );
}