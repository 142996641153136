import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./AuthProvider";
import ThemeProvider, { GlobalStyles } from "./theme";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
	dsn: "https://ec090c51747d4d76ab5ecc6504b56705@o1209587.ingest.sentry.io/6633694",
	integrations: [new BrowserTracing()],
	tracesSampleRate: 0.5,
});

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<AuthProvider>
				<ThemeProvider>
					<GlobalStyles />
					<App />
				</ThemeProvider>
			</AuthProvider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById("root")
);
