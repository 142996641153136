import {
	Button,
	Card,
	CircularProgress,
	Collapse,
	Dialog,
	DialogContent,
	IconButton,
	ImageList,
	ImageListItem,
	ImageListItemBar,
	ListSubheader,
	Menu,
	MenuItem,
	Stack,
	Typography,
	useMediaQuery,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { getScheduledPhotos, removeFromSchedule, setPhotoAsPosted } from "../../../firebase";
import ImageComponent from "material-ui-image";
import * as Icons from "react-icons/all";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { lightGreen } from "@mui/material/colors";

export default function PhotoScheduleView() {
	const [isLoading, setLoading] = useState(true);
	const [scheduled, setScheduled] = useState([]);
	const [dates, setDates] = useState([]);
	const [itemClicked, setItemClicked] = useState();
	const [itemToPost, setItemToPost] = useState();
	const mobile = useMediaQuery((t) => t.breakpoints.down("sm"));

	useEffect(() => {
		getScheduledPhotos()
			.then((i) => {
				const dates = [...new Set(i.map((ii) => ii.scheduledFor))].sort((a, b) => new Date(b) - new Date(a));
				setDates(dates.map((d, i) => ({ date: d, open: !i })));
				setScheduled(i);
				console.log(i);
			})
			.catch(console.error)
			.finally(setLoading);
	}, []);

	const handleRemove = (item) => {
		if (window.confirm("Remove From Schedule?")) {
			removeFromSchedule([item]);
			setItemClicked();
			setScheduled((s) => s.filter((ss) => ss !== item));
		}
		setItemClicked();
	};

	const handlePost = (item) => {
		setItemClicked();
		setItemToPost(item);
	};

	const handleCopySaveGo = async () => {
		try {
			const blob = await fetch(itemToPost.url).then((r) => r.blob());
			const file = new File([blob], "test.png", { type: "image/png" });
			console.log(file);
			if (navigator.share && navigator.canShare({ files: [file] })) {
				const shareData = {
					title: itemToPost.submitter.handle,
					files: [file],
					text: "ig",
				};
				navigator.share(shareData);
			} else {
				throw new Error("Browser does not allow saving");
			}

			setScheduled((s) => {
				itemToPost.status = "POSTING";
				return [...s];
			});

			// GO TO IG
			const igLink = document.createElement("a");

			igLink.href = "instagram://create/story";
			igLink.click();
		} catch (e) {
			window.alert(e);
		}
	};

	const handleReport = () => {
		setScheduled((s) => {
			itemToPost.status = "DENIED";
			return [...s];
		});
		setItemToPost();
	};

	const handleMarkAsPosted = () => {
		const status = itemToPost.status === "POSTED" ? "APPROVED" : "POSTED";
		setPhotoAsPosted(itemToPost, status).then((a) => {
			setScheduled((s) => {
				itemToPost.status = status;
				return [...s];
			});

			setItemToPost();
		});
	};

	const colors = {
		DENIED: "red",
		SCHEDULED: "green",
		POSTED: lightGreen,
	};

	const handleCollapse = (d) => (e) => {
		setDates((dd) => {
			d.open = !d.open;
			return [...dd];
		});
	};

	// const handleOnLoad = ({ target }) => {
	// 	const canvas = document.createElement("canvas");
	// 	canvas.width = target.naturalWidth;
	// 	canvas.height = target.naturalHeight;

	// 	const ctx = canvas.getContext("2d");
	// 	ctx.drawImage(target, 0, 0);
	// 	console.log(target, canvas);
	// 	console.log(canvas.toDataURL());
	// };

	return isLoading ? (
		<CircularProgress />
	) : (
		<Fragment>
			{dates.map((d) => (
				<Card sx={{ m: 1 }} key={d.date}>
					<ListSubheader onClick={handleCollapse(d)} component="div">
						{new Date(d.date).toDateString()}
					</ListSubheader>

					<Collapse unmountOnExit in={d.open}>
						<ImageList cols={mobile ? 2 : 5}>
							{d.open &&
								scheduled
									.filter((i) => i.scheduledFor === d.date)
									.map((item) => (
										<ImageListItem key={item.fullPath}>
											<ImageComponent src={item.url} style={{ objectFit: "cover" }} />
											<ImageListItemBar
												title={<Typography color={item.status === "POSTED" ? "lightgreen" : item.status === "DENIED" && "red"}>{item.submitter.handle}</Typography>}
												sx={{ bgcolor: colors[item.status] }}
												subtitle={new Date(item.timestamp).toDateString()}
												actionIcon={
													<IconButton name={item.id} sx={{ color: "white" }} onClick={(e) => setItemClicked([e.target, item])}>
														<Icons.BiDotsHorizontal />
													</IconButton>
												}
											/>
										</ImageListItem>
									))}
						</ImageList>
					</Collapse>
				</Card>
			))}

			<Menu open={!!itemClicked} anchorEl={itemClicked?.[0]} onClose={() => setItemClicked()}>
				<MenuItem onClick={() => handlePost(itemClicked?.[1])}>Post</MenuItem>
				<MenuItem sx={{ color: "red" }} onClick={() => handleRemove(itemClicked?.[1])}>
					Remove
				</MenuItem>
			</Menu>

			<Dialog open={!!itemToPost} onClose={() => setItemToPost()}>
				<DialogContent>
					<Stack spacing={2}>
						<Typography textAlign={"center"}>{fixHandle(itemToPost?.submitter?.handle)}</Typography>
						<ImageComponent src={itemToPost?.url} style={{ objectFit: "cover" }} />
						{itemToPost?.status === "POSTING" ? (
							<Button variant="contained" onClick={handleMarkAsPosted}>
								{"Mark as Posted!"}
							</Button>
						) : (
							<CopyToClipboard text={fixHandle(itemToPost?.submitter?.handle)} onCopy={handleCopySaveGo}>
								<Button variant="contained">{"Copy, Save & Go to IG"}</Button>
							</CopyToClipboard>
						)}
						{itemToPost?.status === "POSTED" && (
							<Button variant="contained" onClick={handleMarkAsPosted}>
								{"Reset Post Status"}
							</Button>
						)}
						<Button color="error" variant="contained" onClick={handleReport}>
							Report Problem
						</Button>
					</Stack>
				</DialogContent>
			</Dialog>
		</Fragment>
	);
}

function fixHandle(raw = "") {
	return ("@" + raw.trim().toLowerCase()).replace(/@*/, "@");
}
