import { Card, CardHeader, Stack, Typography } from '@mui/material';
import { DataGrid, GridOverlay } from '@mui/x-data-grid';
import { useMemo } from 'react';



export default function Conversions({ userInfo }) {

  const columns = useMemo(() => {
    return [
      { field: "status", headerName: "Status", flex: 0 },
      { field: "created", headerName: "Date", flex: 1 },
      { field: "total", headerName: "Total", flex: 1 },
    ]
  }, [])

  const rows = useMemo(() => {
    if (userInfo.refersion?.conversions) {
      return userInfo.refersion.conversions.map(c => ({
        ...c,
        status: c.status || c.payment_status,
        created: (new Date(c.created * 1000)).toLocaleDateString(),
        total: c.commission_total + " " + c.currency,
      }))
    } else return []
  }, [userInfo.refersion?.conversions])

  return (
    <Card >
      <CardHeader title="Your Conversions" />
      <DataGrid
        pageSize={20}
        autoHeight
        columns={columns}
        rows={rows}
        rowsPerPageOptions={[20]}
        components={{
          NoRowsOverlay: () => (
            <GridOverlay>
              <Stack alignItems='center' justifyContent='center'>
                <Typography>
                  Your conversions will appear here!
                </Typography>
              </Stack>
            </GridOverlay>
          )
        }}
      />
    </Card>
  )
}