import { Grid, Card, CardContent, Button, Divider, Typography, Box, Checkbox } from "@mui/material";
import { useFormik } from 'formik';
import {
  MdEmail as Email,
  MdLock as Lock,
  BsFillPersonFill as Name,
  RiInstagramFill as Instagram,
  MdPhoneIphone as Phone,
  FaBirthdayCake as Cake,
  FaPaypal as Paypal,
  IoBagCheck as OrderNumber,
  MdPets as Pets,
} from "react-icons/all";
import moment from 'moment';
import * as API from '../../firebase';
import { CustomTextField } from "../../components/signup";
import * as yup from 'yup';
import { useAuth } from "../../AuthProvider";





export default function Form({ setLoading, setError }) {
  const { signInWithToken } = useAuth();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      orderNumber: "",
      dogName: "",
      dogBday: undefined,
      igHandle: "",
      paypalEmail: "",
      phoneNumber: "",
      smsOptIn: false
    },
    validationSchema,
    onSubmit: values => {
      setLoading(true);
      values.dogBday = moment(values.dogBday).format("MM/DD/YYYY");
      values.phoneNumber = "+1" + values.phoneNumber
      API.createAmbassador(values)
        .then(({ data }) => {
          signInWithToken(data.token);
        })
        .catch(e => {
          setError(e.message);
          console.error('CATCH', Object.entries(e), e.message);
          setLoading(false);
        })

    },
  })





  return (
    <Card>
      <CardContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4">Program Registration</Typography>
            </Grid>

            <Grid item xs={12}><Divider textAlign='center'>Account Info</Divider></Grid>
            <CustomTextField {...{ id: "email", label: "Email", formik, xs: 12, type: 'email', icon: <Email /> }} required />
            <CustomTextField {...{ id: "password", label: "Password", formik, xs: 6, type: "password", icon: <Lock />, info: <>Requirements:<br />• 8 - 72 Characters<br />• One Uppercase<br />• One Lowercase<br />• One Special Charcter<br />• Can't repeat the same 3 characters</> }} required />
            <CustomTextField {...{ id: "confirmPassword", label: "Confirm Password", formik, xs: 6, type: 'password', icon: <Lock /> }} required />

            <Grid item xs={12}><Divider textAlign='center'>Owner Info</Divider></Grid>
            <CustomTextField {...{ id: "firstName", label: "First Name", formik, xs: 12, md: 6, icon: <Name /> }} required />
            <CustomTextField {...{ id: "lastName", label: "Last Name", formik, xs: 12, md: 6, icon: <Name /> }} required />
            <CustomTextField {...{ id: "igHandle", label: "Instagram Handle", formik, xs: 12, icon: <Instagram />, info: "Optional" }} />
            <CustomTextField {...{ id: "phoneNumber", label: "Phone Number", formik, type: 'tel', icon: <Phone />, xs: 12, info: "Receive exclusive offers and updates!" }} />
            <Grid item hidden>
              <Box sx={{ p: 1, bgcolor: 'grey' }}>
                <Checkbox value={formik.values.smsOptIn} onChange={(...a) => console.log(a)} />
                <Typography variant='caption'>
                  {"By signing up via text, you agree to receive recurring automated marketing messages, including cart reminders, offers, and other goodies for your pup, at the phone number provided. Consent is not a condition of sign-up. Reply STOP to unsubscribe. Reply HELP for help. Message frequency varies. Msg & data rates may apply. View our Privacy Policy and Terms of Service."}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12}><Divider textAlign='center'>Pup Info</Divider></Grid>
            <CustomTextField {...{ id: "dogName", label: "Dog's Name", formik, xs: 12, icon: <Pets />, info: "Signing up multiple dogs? Add in your other pups after you create your account!" }} required />
            <CustomTextField {...{ id: "dogBday", label: "Dog's Birthday (Gotcha Day)", formik, xs: 12, type: 'date', icon: <Cake /> }
            } required />

            <Grid item xs={12}><Divider textAlign='center'>Commission Info</Divider></Grid>
            <CustomTextField {...{ id: "paypalEmail", label: "PayPal Email", type: 'email', formik, xs: 12, icon: <Paypal />, info: "This is where your commissions payments will be sent to" }} />

            <CustomTextField {...{ id: "orderNumber", label: "Your Recent Order Number", formik, xs: 12, type: 'number', icon: <OrderNumber /> }} />


            <Grid item xs>
              <Button
                fullWidth
                variant='contained'
                type='submit'
                disabled={false}
              >Sign Up</Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card >
  )
}





const validationSchema = yup.object({
  firstName: yup
    .string("")
    .required("Required"),
  lastName: yup
    .string()
    .required("Required"),
  email: yup
    .string()
    .email()
    .required("Required"),
  password: yup
    .string()
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{9,72})/, "Must Contain 9-72 Characters, One Uppercase, One Lowercase, One Number and One Special Character")
    .required("Required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password does not match")
    .required("Required"),
  orderNumber: yup
    .string()
    .min(4, "That doesn't seem like an order number")
    .max(6, "That doesn't seem like an order number")
    .required("Required"),
  dogName: yup
    .string()
    .required("Required"),
  dogBday: yup
    .date()
    .required("Required"),
  igHandle: yup
    .string(),
  paypalEmail: yup
    .string()
    .email(),
  phoneNumber: yup
    .string(),
  smsOptIn: yup
    .boolean()
})

