import { Grid, Button, AppBar } from "@mui/material";
import { useNavigate } from "react-router";
import Logo from "../Logo";





export default function Header() {
  const nav = useNavigate();
  return (
    <AppBar>
      <Grid container sx={{ bgcolor: 'black', height: 40 }} justifyContent="center" alignItems='center'>
        <Grid item >
          <Logo />
        </Grid>
        <Button sx={{ position: 'absolute', right: 0, color: 'white' }} onClick={() => nav("/login")} >Login</Button>
      </Grid>
    </AppBar>
  )
}