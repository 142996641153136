import { Navigate } from "react-router-dom";
import { MainLayout, DashboardLayout, AdminLayout } from "./layouts";
import { useMemo } from "react";
import { SimpleSignUpForm, LoginView, DashboardView, AccountView, LogoutView, PhotoSubmissionView, ModelsView, PayoutView, ForgotView } from "./views/endUser";
import { AmbassadorsView, AmbassadorView, PhotosView, PhotoScheduleView, NewAmbassadorView } from "./views/admin";
import SubmissionsView from "./views/admin/SubmissionsView";

/**
 * @param {Boolean} isAdmin
 * @returns {import("react-router").PartialRouteObject[]}
 */
export default function CreateRoutes({ isSignedIn, scopes }) {
	const isAdmin = scopes.includes("admin");
	return useMemo(
		() => [
			{
				path: "/",
				element: <MainLayout />,
				children: [
					{
						path: "/",
						element: isSignedIn ? <Navigate to="/dashboard" /> : <Navigate to="/signup" />,
					},
					{
						path: "signup",
						element: isSignedIn ? <Navigate to="/dashboard" /> : <SimpleSignUpForm />,
					},
					{
						path: "login",
						element: isSignedIn ? <Navigate to={isAdmin ? "/admin" : "/dashboard"} /> : <LoginView />,
					},
					{
						path: "forgot",
						element: <ForgotView />,
					},
					{
						path: "logout",
						element: <LogoutView />,
					},
				],
			},
			{
				path: "dashboard",
				element: isSignedIn ? <DashboardLayout /> : <Navigate to="/login" />,
				children: [
					{
						path: "/",
						element: <DashboardView />,
					},
					{
						path: "account",
						element: <AccountView />,
					},
					{
						path: "photo-submission",
						element: <PhotoSubmissionView />,
					},
					{
						path: "models",
						element: <ModelsView />,
					},
					{
						path: "payouts",
						element: <PayoutView />,
					},
					{
						path: "*",
						element: <Navigate to="/dashboard" />,
					},
				],
			},
			{
				path: "admin",
				element: isAdmin ? <AdminLayout /> : <Navigate to="/dashboard" />,
				children: [
					{
						path: "/",
						element: <Navigate to="/admin/ambassadors" />,
					},
					{
						path: "ambassadors",
						element: <AmbassadorsView />,
					},
					{
						path: "ambassadors/new",
						element: <NewAmbassadorView />,
					},
					{
						path: "ambassadors/:ambassadorId",
						element: <AmbassadorView />,
					},
					{
						path: "ambassadors/:ambassadorId/submissions",
						element: <SubmissionsView />,
					},
					{
						path: "photos",
						element: <PhotosView />,
					},
					{
						path: "photo-scheduler",
						element: <PhotoScheduleView />,
					},
				],
			},
			{
				path: "/*",
				element: <Navigate to="/" />,
			},
		],
		[isSignedIn, isAdmin]
	);
}
