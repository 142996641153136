import { Grid, Autocomplete, Dialog, DialogTitle, DialogContent, DialogActions, Button, Avatar, colors, IconButton, Chip } from "@mui/material";
import { Add, Cake, Pets, Star } from "@material-ui/icons";
import React, { useState } from "react";
import breeds from "../../../breeds";
import avatars from "../../../avatars";
import { LoadingBackDrop, TextField, ContinueButton, ErrorPopUp } from "../../../components/general";
import { useAuth } from "../../../AuthProvider";
import moment from 'moment';




export default function ModelsView() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { userInfo, updateUser } = useAuth();
  const [pets, setPets] = useState(userInfo.pets || [{ ...stockPet }]);
  const [petIndex, setPetIndex] = useState(0);
  const [avatarOpen, setAvatarOpen] = useState(false);
  const pet = pets[petIndex];



  const handleChange = (key) => (value) => {
    if (key === 'bday') {
      const parts = value.split('-');
      value = parts[1] + "/" + parts[2] + "/" + parts[0];
    }
    
    setPets(pets => {
      pet[key] = value;
      return [...pets]
    })
  }



  const addNewModel = () => {
    setPets(pets => [...pets, { ...stockPet }]);
    setPetIndex(pets.length - 1)
  }



  const removeModel = () => {
    setPets(pets => {
      pets.splice(petIndex, 1);
      return [...pets]
    });
    setPetIndex(pets.length - 1);
  }



  const handleSubmit = () => {
    setLoading(true);

    updateUser({ pets }).then(() => {
      console.log('updated user')
      setLoading(false);
    }).catch(e => {
      setError(e.message || e.code);
      setLoading(false)
    })
  }



  return (
    <Grid container direction="column" alignItems='center' wrap="nowrap" sx={styles.root} >
      <AvatarContainer {...{ pets, setPetIndex, petIndex, addNewModel }} />


      {/* AVATAR CONTROLS */}
      <Grid container item justifyContent='space-around' sx={{ pb: 2 }}>
        <Grid item  >
          <Chip label="Select Avatar" onClick={() => setAvatarOpen(true)} sx={{ bgcolor: colors.blue[400], color: 'white' }} />
        </Grid>
        <Grid item >
          <Chip label="Remove Model" onClick={removeModel} sx={{ bgcolor: 'red', color: 'white', display: pets.length < 2 && 'none' }} />
        </Grid>
      </Grid>



      {/* MAIN CONTENT */}
      <Grid container item direction="column" spacing={3} xs md={'auto'} sx={{ width: '100%' }} >
        <Grid item  >
          <TextField placeholder='Pet Name*' value={pet?.name} onTextChange={handleChange('name')} startAdornment={<Star />} />
        </Grid>
        <Grid item>
          <TextField type='date' label='Gotcha Day' value={moment(pet?.bday).format("yyyy-MM-DD")} onTextChange={handleChange('bday')} shrink startAdornment={<Cake />} />
        </Grid>
        <Grid item>
          <Autocomplete value={pet.breed} autoSelect freeSolo options={pet?.breed.length ? breeds : []} onSelect={({ target }) => handleChange('breed')(target.value)} renderInput={(params) => (
            <TextField  {...params} placeholder='Breed' startAdornment={<Pets />} />
          )} />
        </Grid>
      </Grid>



      {/* CONTINUE BUTTON */}
      <Grid item sx={{ py: 2, width: '100%' }}>
        <ContinueButton disabled={!pet?.name} onClick={handleSubmit} id="btn" />
      </Grid>




      {/* ADD MODEL POPUP */}
      <Dialog open={avatarOpen} keepMounted onClose={() => setAvatarOpen(false)} >
        <DialogTitle>Choose your model's avatar</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ pt: 1 }} >
            {avatars.map((a, i) => (
              <Grid key={i} item xs={4} style={{ padding: 0 }} >
                <center>
                  <IconButton onClick={() => handleChange('avatarId')(a.id) || setAvatarOpen(false)}>
                    <Avatar src={a.url} sx={styles.avatar()} />
                  </IconButton>
                </center>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAvatarOpen(false)} >close</Button>
        </DialogActions>
      </Dialog>




      <LoadingBackDrop open={loading} />
      <ErrorPopUp open={!!error} onClose={() => setError(false)} content={error} />
    </Grid>
  )
}



const styles = {
  root: {
    height: '100%',
    width: '100%',
    maxWidth: 600,
    margin: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'scroll'
  },
  avatar: (selected) => ({
    width: 80,
    height: 80,
    backgroundColor: selected ? colors.blue[400] : colors.blue[200]
  }),
  addAvatar: {
    width: 40,
    height: 40,
    backgroundColor: colors.blue[200],
    margin: 3,
  },
  avatarContainer: {
    overflowX: 'auto',
    margin: 0,
    width: '100%',
    //minHeight: 160,
  },





  header: {
    color: '#333',
    fontFamily: 'Poppins',
    fontSize: '2.4rem',
    fontWeight: 800,
    marginTop: 40,
  },
  text: {
    color: '#333',
    marginTop: 40,
    fontFamily: 'Poppins',
  },
  input: {
    textAlign: 'center',
    color: '#333',
    fontFamily: 'Poppins',
  },
  link: {
    fontFamily: 'Poppins',
  },
  backdrop: {
    //zIndex: theme.zIndex.drawer
  },
  paper: {
    position: 'absolute',
    width: 400,

  }
}



const stockPet = {
  name: '',
  bday: '',
  breed: '',
  igHandle: "",
  avatarId: 0,
}


const AvatarContainer = ({ pets, setPetIndex, petIndex, addNewModel }) => (
  <Grid container item spacing={2} wrap="nowrap" justifyContent='center' alignItems='center' sx={styles.avatarContainer} >
    {pets.map((p, i) => (
      <Grid item key={i}>
        <IconButton onClick={() => setPetIndex(i)} >
          <Avatar alt={p.name} src={(avatars.find(a => a.id === p.avatarId) || avatars[0]).url} style={styles.avatar(petIndex === i)} />
        </IconButton>
      </Grid>

    ))}
    <Grid item>
      <IconButton disabled={pets.length >= 5} onClick={addNewModel} >
        <Avatar alt='add' sx={styles.addAvatar}><Add /></Avatar>
      </IconButton>
    </Grid>
  </Grid>
)