import { Button, Card, CardContent, Divider, Stack, TextField, Typography, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { LoadingBackDrop } from "../../../components/general";
import { getAffiliate, getUserByEmail, getUserDoc, manuallyCreateAmbassador } from '../../../firebase';
import emailValidation from '../../../helpers/emailValidation';





export default function NewAmbassadorView() {
  const [checkedEmail, setCheckedEmail] = useState(false);
  const [doc, setDoc] = useState({
    id: "",
    email: "",
    firstName: "",
    lastName: "",
    refersion_id: "",
    affiliate_code: "",
    refersionLink: "",
    phoneNumber: "",
    igHandle: "",
    pets: []
  });
  const [importOpen, setImportOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const nav = useNavigate();



  const importFromRefersion = (id) => {
    setLoading(true);
    return getAffiliate(id).then((affiliate) => {
      const [rp, refersion_id, affiliate_code] = affiliate.rfsn_parameter.match(/rfsn=(.*)\.(.*)$/);
      const refersionLink = "https://www.packleashes.com/?" + rp;
      setCheckedEmail(true);
      affiliate.custom_fields = Object.fromEntries(affiliate.custom_fields.map(f => f.slice(1)));
      setDoc(d => ({
        ...d,
        email: affiliate.email || d.email,
        createdAt: affiliate.created * 1000,
        firstName: affiliate.first_name || d.firstName,
        lastName: affiliate.last_name || d.lastName,
        phoneNumber: affiliate.phone_number,
        igHandle: affiliate.custom_fields["Instagram Handle"],
        pets: [
          {
            name: affiliate.custom_fields["Dog's Name"],
            breed: "",
            avatarId: 0,
            bday: "01/01/2001"
          }
        ],
        affiliate_code,
        refersion_id,
        refersionLink,
      }))
      setImportOpen(false);
    })
  }



  const handleChange = (key) => ({ target }) => {
    setDoc(doc => ({
      ...doc,
      [key]: target.value
    }))
  }



  const handleCreate = () => {
    setLoading(true);
    manuallyCreateAmbassador(doc)
      .then((id) => nav("/admin/ambassadors/" + id))
      .catch(e => console.error(Object.keys(e)) || window.alert(e.message))
      .finally(() => setLoading(false));
  }



  useEffect(() => {
    if (!checkedEmail) {
      let timeout;
      setCheckedEmail(false);
      if (emailValidation(doc.email)) {
        timeout = setTimeout(() => {
          getUserByEmail(doc.email).then((user) => {
            if (user) {
              getUserDoc(user.uid).then(userDoc => {
                userDoc = userDoc || {};
                const [firstName, ...lastName] = user.displayName.split(" ");
                setDoc(doc => ({
                  email: doc.email,
                  ...({
                    id: "",
                    refersion_id: "",
                    affiliate_code: "",
                    refersionLink: "",
                    igHandle: "",
                    pets: []
                  }),
                  ...userDoc,
                  id: user.uid,
                  firstName: userDoc.firstName || firstName || "",
                  lastName: userDoc.lastName || lastName.join(" ") || "",
                  phoneNumber: userDoc.phoneNumber || user.phoneNumber || "",
                }))
              }).catch(e => console.error(e) || window.alert("getUserDoc\n" + JSON.stringify(e)));
            } else {
              setDoc({
                id: "",
                email: doc.email,
                firstName: "",
                lastName: "",
                refersion_id: "",
                affiliate_code: "",
                refersionLink: "",
                phoneNumber: "",
                igHandle: "",
                pets: []
              })
            }
            setCheckedEmail(true);
          })
        }, 2000);
      }

      return () => {
        if (timeout) {
          clearInterval(timeout);
        }
      }
    }
  }, [doc.email, checkedEmail]);



  return (
    <Card sx={{ m: 1 }}>
      <CardContent>
        <Button variant='contained' onClick={() => setImportOpen(true)}>Import from refersion</Button>
        <Stack spacing={1}>

          <Divider><Typography>Ambassador Info</Typography></Divider>
          <TextField label="Email" value={doc.email} fullWidth onChange={handleChange('email')} />
          {checkedEmail && (
            <>
              <TextField label="First Name" value={doc.firstName} fullWidth onChange={handleChange('firstName')} />
              <TextField label="Last Name" value={doc.lastName} fullWidth onChange={handleChange('lastName')} />
              <TextField label="Phone Number" value={doc.phoneNumber} fullWidth onChange={handleChange('phoneNumber')} />

              <Divider>Refersion</Divider>
              <TextField label="Refersion ID" value={doc.refersion_id} fullWidth onChange={handleChange('refersion_id')} />
              <TextField label="Affiliate Code" value={doc.affiliate_code} fullWidth onChange={handleChange('affiliate_code')} />


              <Divider>Instagram</Divider>
              <TextField label="Handle" value={doc.igHandle} fullWidth onChange={handleChange('igHandle')} />
              <Button disabled={!(doc.email && !(doc.id && doc.refersion_id))} variant='contained' onClick={handleCreate}>Create BAP account</Button>
            </>
          )}
        </Stack>
      </CardContent>
      <ImportDialog {...{ open: importOpen, onClose: () => setImportOpen(false), importFromRefersion, setLoading }} />
      <LoadingBackDrop open={isLoading} />
    </Card>
  )
}












function ImportDialog({ open, onClose, importFromRefersion, setLoading }) {
  const [refersionId, setRefersionId] = useState("");




  const handleImport = () => {
    importFromRefersion(refersionId).catch(e => {
      console.error(e)
      window.alert(JSON.stringify(e))
    }).finally(() => setLoading(false));
  }




  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Import from Refersion</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <TextField label="Refersion ID" value={refersionId} onChange={({ target }) => setRefersionId(target.value)} />
          <Button fullWidth variant="contained" onClick={handleImport}>Import</Button>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}