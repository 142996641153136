import {
	Card,
	IconButton,
	ImageList,
	ImageListItem,
	ImageListItemBar,
	Box,
	SpeedDial,
	SpeedDialAction,
	Typography,
	SpeedDialIcon,
	Zoom,
	useMediaQuery,
	CircularProgress,
	Dialog,
	DialogContent,
	TextField,
	Stack,
	Button,
	CardContent,
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { getSubmissions, reportPhotos, schedulePhotos } from "../../../firebase";
import Image from "material-ui-image";
import * as Icons from "react-icons/all";

export default function PhotosView() {
	const [date, setDate] = useState(new Date().toISOString().replace(/T.*/, ""));
	const [images, setImages] = useState([]);
	const [isEnd, setIsEnd] = useState(false);
	const mobile = useMediaQuery((t) => t.breakpoints.down("sm"));
	const [loading, setLoading] = useState(true);
	const [scheduleOpen, setScheduleOpen] = useState(false);
	const [scheduledDate, setScheduledDate] = useState();
	const [reportDialogOpen, setReportDialogOpen] = useState(false);
	const cursorRef = useRef();

	const loadMore = useCallback((date) => {
		setLoading(true);
		getSubmissions(date, cursorRef.current)
			.then((i) => {
				if (i.length) {
					setImages((e) => [...e, ...i.map((ii) => ({ ...ii, selected: false }))]);
					cursorRef.current = i[i.length - 1].timestamp;
					setIsEnd(false);
				} else {
					setIsEnd(true);
				}
			})
			.catch(console.error)
			.finally(() => setLoading(false));
	}, []);

	useEffect(() => {
		if (date) {
			setImages([]);
			loadMore(date);
		}
	}, [loadMore, date]);

	const handleSelect = (item) => {
		setImages((i) => {
			item.selected = !item.selected;
			return [...i];
		});
	};

	const selected = images.filter((i) => i.selected).length;

	const handleReport = (reason) => () => {
		if (window.confirm(`Report ${selected} images for\n${reason}?`)) {
			const selectedImages = images.filter((i) => i.selected);
			reportPhotos(selectedImages, reason).then(() => {
				setImages((i) => {
					selectedImages.forEach((ii) => {
						ii.status = "DENIED";
						ii.reason = reason;
						ii.selected = false;
					});
					return [...i];
				});
			});
		}
		setReportDialogOpen(false);
	};

	const handleSchedule = () => {
		const selectedImages = images.filter((i) => i.selected);
		schedulePhotos(selectedImages, scheduledDate)
			.then(() => {
				setImages((i) => {
					selectedImages.forEach((i) => {
						i.scheduledFor = scheduledDate;
						i.status = "SCHEDULED";
						i.selected = false;
					});
					return [...i];
				});
				setScheduleOpen(false);
			})
			.catch(console.error);
	};

	const colors = {
		DENIED: "red",
		SCHEDULED: "green",
	};

	return (
		<Box sx={{ height: "100%", width: "100%", p: 1 }}>
			<Stack spacing={1} sx={{ height: "100%" }}>
				<Card sx={{ minHeight: 88 }}>
					<CardContent>
						<center>
							<TextField value={date} onChange={({ target }) => setDate(target.value)} type="date" />
						</center>
					</CardContent>
				</Card>

				<Card sx={{ overflowY: "auto" }}>
					<CardContent>
						<ImageList cols={mobile ? 2 : 5}>
							{images.map((item) => (
								<ImageListItem key={item.id}>
									<Image src={item.url} style={{ objectFit: "cover" }} />
									<ImageListItemBar
										title={item.submitter.handle}
										sx={{ backgroundColor: colors[item.status] }}
										subtitle={new Date(item.scheduledFor || item.timestamp).toDateString()}
										actionIcon={
											<IconButton style={{ color: item.scheduledFor ? "green" : "goldenrod" }} onClick={(e) => e.scheduledFor || handleSelect(item)}>
												{item.scheduledFor ? <Icons.BiCheck /> : item.selected ? <Icons.BsFillCircleFill /> : <Icons.BiCircle />}
											</IconButton>
										}
									/>
								</ImageListItem>
							))}
						</ImageList>
						<Box sx={{ p: 1, display: "flex", width: "100%", justifyContent: "center" }}>
							<BottomArea isEnd={isEnd} hasImages={!!images.length} loading={loading} loadMore={() => loadMore(date)} />
						</Box>
					</CardContent>
				</Card>
			</Stack>

			<Zoom in={!!selected}>
				<SpeedDial ariaLabel="" FabProps={{ sx: { opacity: 0.8 } }} sx={{ position: "absolute", bottom: 16, right: 16 }} icon={<SpeedDialIcon icon={selected} />}>
					<SpeedDialAction
						onClick={() => setScheduleOpen(true)}
						FabProps={{ size: "large", sx: { bgcolor: "goldenrod", color: "white" } }}
						key={0}
						tooltipOpen
						icon={<Icons.BsStarFill />}
						tooltipTitle="Schedule"
					/>
					<SpeedDialAction
						onClick={() => setReportDialogOpen(true)}
						FabProps={{ size: "large", sx: { bgcolor: "red", color: "white" } }}
						key={1}
						tooltipOpen
						icon={<Icons.BiError />}
						tooltipTitle="REPORT"
					/>
				</SpeedDial>
			</Zoom>

			<Dialog open={scheduleOpen} onClose={() => setScheduleOpen(false)}>
				<DialogContent>
					<Stack spacing={2}>
						<Typography>Schedule {selected} photos</Typography>
						<TextField value={scheduledDate} onChange={({ target }) => setScheduledDate(target.value)} type="date" />
						<Button variant="contained" onClick={handleSchedule}>
							Schedule
						</Button>
					</Stack>
				</DialogContent>
			</Dialog>

			<ReportDialog {...{ open: reportDialogOpen, onClose: () => setReportDialogOpen(false), handleReport, selected }} />
		</Box>
	);
}

function ReportDialog({ open, onClose, handleReport, selected }) {
	const [isOther, setIsOther] = useState(false);
	const [reason, setReason] = useState("");

	useEffect(() => {
		setIsOther(false);
		setReason("");
	}, [open]);

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogContent>
				<Stack spacing={2}>
					<Typography textAlign="center">Report {selected} photo(s)</Typography>
					{isOther ? (
						<>
							<TextField variant="outlined" label="Other reason:" value={reason} onChange={({ target }) => setReason(target.value)} />
							<Button variant="contained" onClick={handleReport(reason)}>
								submit
							</Button>
						</>
					) : (
						<>
							<Button variant="contained" onClick={handleReport("Not Pack Gear")}>
								Not pack gear
							</Button>
							<Button variant="contained" onClick={handleReport("Not a Dog")}>
								Not a dog
							</Button>
							<Button variant="contained" onClick={handleReport("Low Image Quality")}>
								Low image quality
							</Button>
							<Button variant="contained" onClick={() => setIsOther(true)}>
								other
							</Button>
						</>
					)}
				</Stack>
			</DialogContent>
		</Dialog>
	);
}

function BottomArea({ isEnd, hasImages, loading, loadMore }) {
	if (isEnd) {
		if (hasImages) {
			return "No More Photos";
		} else return "No Photos For This Date";
	} else if (loading) {
		return <CircularProgress />;
	} else {
		return (
			<Button fullWidth onClick={loadMore}>
				Load More
			</Button>
		);
	}
}
