import { Card, Dialog, DialogContent, MenuItem, Select, Stack, TextField, Button, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { getAmbassadors, searchForAmbassador } from '../../../firebase';
import * as Icons from "react-icons/all";




export default function AmbassadorsView() {
  const [ambassadors, setAmbassadors] = useState([]);
  const [page, setPage] = useState(0);
  const [searchOpen, setSearchOpen] = useState(false);
  const [search, setSearch] = useState();
  const searchRef = useRef();
  const searchFieldRef = useRef();
  const nav = useNavigate();


  useEffect(() => {
    getAmbassadors().then(u => setAmbassadors(a => [...a, ...u]));
  }, [])




  const columns = [
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'fullName',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'refersion_id',
      headerName: 'Refersion ID',
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 1,
      renderCell: a => a.value && (new Date(a.value)).toLocaleString()
    },
  ]



  const getPage = (newPage, idk) => {
    if (ambassadors.length <= newPage * 100) {
      getAmbassadors(ambassadors[ambassadors.length - 1]?.createdAt).then(u => setAmbassadors(a => [...a, ...u]));
    }
    setPage(newPage);
  }



  const handleRowClick = (row) => {
    nav(row.id)
  }



  const handleSearch = () => {
    const searchField = searchFieldRef.current.value;
    const search = searchRef.current.value;
    searchForAmbassador(searchField, search).then(results => {
      setSearch(true);
      setAmbassadors(results);
      setSearchOpen(false);
      setPage(0);
    }).catch(console.error)
  }



  const handleClearSearch = () => {
    setSearch(false);
    getPage(0);
  }




  const handleAddAmbassador = () => {
    nav("new");
  }



  return (
    <Stack>
      <Card sx={{ m: 1 }}>
        <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ p: 1 }}>
          <>Ambassadors</>
          <Stack direction='row'>
            {search ? (
              <IconButton onClick={handleClearSearch}><Icons.MdSearchOff /></IconButton>
            ) : (
              <IconButton onClick={() => setSearchOpen(true)}><Icons.BiSearch /></IconButton>
            )}

            <IconButton onClick={handleAddAmbassador}><Icons.CgAdd /></IconButton>
          </Stack>
        </Stack>

        <DataGrid
          page={page}
          pageSize={100}
          autoHeight
          columns={columns}
          rowCount={35000}
          rows={ambassadors || []}
          rowsPerPageOptions={[100]}
          onPageChange={getPage}
          onRowClick={handleRowClick}
        />
      </Card >

      <Dialog open={searchOpen} onClose={() => setSearchOpen(false)}>
        <DialogContent>
          <Stack spacing={2}>
            <Select inputRef={searchFieldRef}>
              <MenuItem value="email">Email</MenuItem>
              <MenuItem value="firstName">First Name</MenuItem>
              <MenuItem value="lastName">Last Name</MenuItem>
              <MenuItem value="refersion_id">Refersion ID</MenuItem>
            </Select>
            <TextField label='Search' inputRef={searchRef} fullWidth />
            <Button variant='outlined' onClick={handleSearch}>Search</Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </Stack >
  )
}