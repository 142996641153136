import { Avatar, Box, Card, CardContent, Grid, Typography, colors } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import * as Icons from 'react-feather';







export default function CurrentPayout({ userInfo }) {
  const [payout, setPayout] = useState(0);



  const from = useMemo(() => {
    const from = new Date();
    from.setMonth(from.getMonth() - 0);
    from.setHours(0, 0, 0, 0);
    from.setDate(1);
    return from;
  }, []);



  const to = useMemo(() => {
    const to = new Date(from);
    to.setMonth(to.getMonth() + 1);
    to.setDate(0);
    return to;
  }, [from]);



  useEffect(() => {
    if (userInfo.refersion?.conversions) {
      const thisMonthsConversions = userInfo.refersion.conversions.filter(c => {
        const date = new Date(c.created * 1000);
        return date > from && date < to;
      });
      setPayout(thisMonthsConversions.reduce((p, c) => p + +c.commission_total, 0));
    }
  }, [userInfo.refersion?.conversions, from, to]);



  const payoutDate = new Date(to.valueOf() + 24 * 60 * 60 * 1000);
  const p = userInfo.refersion?.paypal_email;
  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: 'space-between' }} >
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant='h6' >
              NEXT PAYOUT ({payoutDate.toLocaleDateString()})
            </Typography>
            <Typography color="textPrimary" variant={p ? "h3" : 'body'} >
              {p ? "$" + payout.toFixed(2) : "Set up your PayPal info"}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar sx={{ backgroundColor: p ? colors.green[600] : colors.red[400], height: 56, width: 56 }} >
              {p ? <Icons.DollarSign /> : <Icons.AlertCircle />}
            </Avatar>
          </Grid>
        </Grid>
        <Box sx={{ pt: 2, display: 'flex', alignItems: 'center' }} >
          <Typography color="textSecondary" variant="caption" >
            {p && from.toLocaleDateString() + " - " + to.toLocaleDateString()}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}