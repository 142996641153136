import { Dialog, CircularProgress } from '@mui/material';
import { forwardRef } from 'react';
import { useRoutes } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import CreateRoutes from './routing/routes';








export default function App() {
  const { isReady, isSignedIn, scopes  } = useAuth();
  const routing = useRoutes(CreateRoutes({ isSignedIn, scopes }));

  return isReady ? routing : <Dialog open PaperComponent={forwardRef((props, ref) => <CircularProgress {...{ ref, ...props }} />)} />;
}




