import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { changeAffiliateCode, editAffiliate } from "../../../firebase";
import { useAuth } from "../../../AuthProvider";
import { ContinueButton, LoadingBackDrop, TextField } from "../../../components/general";
import { MdAlternateEmail, FaPaypal } from "react-icons/all";







export default function AccountView() {
  const [loading, setLoading] = useState(false);
  const [mutated, setMutated] = useState(false);
  const { userInfo, updateUser } = useAuth();
  const [values, setValues] = useState({});
  const [newTrigger, setNewTrigger] = useState(userInfo.conversion_triggers?.[0]?.trigger || "");
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [error, setError] = useState(false);



  useEffect(() => {
    if (userInfo) {
      setValues({ ...userInfo, refersion: { ...userInfo.refersion } });
    }
  }, [userInfo])



  const handleValueChange = (key) => (value) => {
    setMutated(true);
    setValues(v => ({ ...v, [key]: value }));
  }



  const handleRefersionChange = (key) => (value) => {
    setMutated(true);
    setValues(v => {
      v.refersion[key] = value;
      return { ...v };
    })
  }



  const handleUpdate = async () => {
    setLoading(true);
    if (values.refersion.paypal_email && values.refersion.paypal_email !== userInfo.refersion.paypal_email) {
      await editAffiliate(userInfo.refersion_id, { paypal_email: values.refersion.paypal_email });
    }
    await updateUser(values);
    setLoading(false);
  }



  const handleChangeCode = () => {
    setLoading(true);
    changeAffiliateCode(userInfo.refersion_id, userInfo.affiliate_code, userInfo.conversion_triggers[0]?.trigger, newTrigger).then(({ data }) => {
      updateUser({ conversion_triggers: data });
    }).catch(e => console.error(e.code, e.message) || setError("Unable to update your code")).finally(setLoading);
  }



  return (
    <Container maxWidth="sm" sx={{ pt: 2, height: '100%' }}>
      <Grid container direction='column' spacing={4} sx={{ height: '100%' }}>
        <Grid item>
          <h2>Your Account</h2>
        </Grid>

        <Grid item>
          <TextField
            label="IG Handle"
            value={values.igHandle || ""}
            onTextChange={handleValueChange('igHandle')}
            startAdornment={<MdAlternateEmail />}
          />
        </Grid>

        <Grid item>
          <TextField
            label="PayPal Email"
            value={values.refersion?.paypal_email || ""}
            onTextChange={handleRefersionChange('paypal_email')}
            startAdornment={<FaPaypal />}
          />
        </Grid>

        <Grid item hidden>
          Your Friends and Family Code:
          <Button disabled sx={{ width: '100%', height: 60 }} variant='outlined' onClick={() => setDialogOpen(true)}>{userInfo.conversion_triggers?.[0]?.trigger || "Create your friends and family code"}</Button>
        </Grid>

        <Grid item xs />

        <Grid item>
          <ContinueButton title='update' disabled={!mutated} onClick={handleUpdate} />
        </Grid>
        <LoadingBackDrop open={!!loading} />
      </Grid>

      <Dialog open={isDialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>New Friends and Family Discount</DialogTitle>
        <DialogContent>
          <TextField error={!!error} value={newTrigger} onTextChange={v => setNewTrigger(v.toUpperCase().replace(/[^0-9a-z]/gi, ""))} />
          <Typography component='center' color='error'>{error}</Typography>
        </DialogContent>
        <DialogActions>
          <Button disabled={!newTrigger.length > 3 || error} onClick={handleChangeCode}>submit</Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}




