import { Container, Grid, Button, Typography, Box, Dialog, DialogContent, CircularProgress, Stack, DialogActions } from "@mui/material";
import {
  BiError as Error,
} from "react-icons/all";
import { useState } from "react";
import { Header, Banner, InfoCard, Form } from "../../../components/signup";




export default function SimpleSignUpForm() {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  

  return (
    <Box width='100%' bgColor='#a7b5ca' sx={{ overflowY: 'auto' }} >
      <Container sx={{ py: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header />
          </Grid>

          <Grid item xs={12}>
            <Banner />
          </Grid>

          <Grid item container spacing={2}>
            <Grid item xs={12} md={6}>
              <Form {...{ setLoading, setError }} />
            </Grid>

            <Grid item xs={12} md={6}>
              <InfoCard />
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Dialog open={error}>
        <DialogContent>
          <Stack alignItems='center' spacing={2}>
            <Error size={80} />
            <Typography>{error}</Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button fullWidth variant='contained' onClick={() => setError(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={!!isLoading} PaperComponent={CircularProgress} />
    </Box>
  )
}









