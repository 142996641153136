import { useEffect, useState, createContext, useContext, useReducer } from 'react';
import { getUserDoc, setAttributes, getAffiliate, auth } from './firebase';
import { useNavigate } from 'react-router';








const authContext = createContext();




export function AuthProvider({ children }) {
  const value = useProvideAuth();
  return <authContext.Provider {...{ value, children }} />
}




export const useAuth = () => {
  return useContext(authContext);
}




function useProvideAuth() {
  const [isSignedIn, setSignedIn] = useState(null);
  const [user, setUser] = useState();
  const [userInfo, userDispatch] = useReducer((user, action) => {
    if (action.update) {
      return { ...user, ...action.update };
    } else if (action.set) {
      return { ...user, ...action.set, refersion: { ...(user.refersion || {}), ...action.set.refersion } };
    } else if (action.reset) {
      return {}
    } else console.error(action);
  }, {})
  const nav = useNavigate();



  useEffect(() => {

    auth.onIdTokenChanged(async user => {
      if (user) {
        try {
          user.scopes = (await user.getIdTokenResult()).claims?.scopes || [];
        } catch (e) {
          console.error(e);
          user.scopes = [];
        }
        setUser(user);
        getUserDoc().then(async doc => {
          console.log({ doc });
          if (doc.refersion_id) {
            await getAffiliate(doc.refersion_id).then(affiliate => {
              doc.refersion = affiliate;
            }).catch((e) => {
              console.error(e);
              doc.refersion = {};
            })
          } else {
            doc.refersion = {};
          }
          userDispatch({ set: doc });
          setSignedIn(true);
        }).catch(console.error);
      } else {
        setSignedIn(false);
        userDispatch({ reset: true });
      }

    })

  }, [nav])






  const signIn = (email, password) => {
    return auth.signInWithEmailAndPassword(email, password);
  }




  const signInWithToken = (token) => {
    return auth.signInWithCustomToken(token);
  }




  const signOut = () => {
    // document.cookie = `bap_uid=; domain=.packleashes.com; path=/; expires=Thu, 01 Jan 2000 00:00:00 UTC`;
    return auth.signOut();
  }




  const updateUser = ({ refersion = {}, id, ...data }) => {

    return setAttributes(data).then(({ data }) => {
      userDispatch({ set: { ...data, id: id || userInfo.id, refersion } });
    });
  }






  return {
    isReady: isSignedIn ? userInfo?.refersion !== undefined : isSignedIn !== null,
    scopes: user?.scopes || [],
    error: isSignedIn && userInfo.refersion === false && "There seems to be an issue with your account",
    signIn,
    signInWithToken,
    signOut,
    isSignedIn: !!isSignedIn,
    userInfo,
    updateUser,
    auth
  }


};
