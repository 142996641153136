import Image from 'material-ui-image';


const Logo = (props) => (
  <Image 
    color='transparent' 
    style={{ width: 100 }} 
    aspectRatio={4} 
    imageStyle={{ height: "100%", width: '100%' }} 
    src="https://cdn.shopify.com/s/files/1/1649/7215/files/PACK_LOGO_WHITE_TM_280x@2x.png?v=1608322523" 
    />
);

export default Logo;
