import { Grid, Card, CardContent, CardHeader, Hidden } from "@mui/material";
import Image from 'material-ui-image';

export default function InfoCard() {

  return (
    <Card>
      <Hidden mdDown>
        <Image style={{ height: 400 }} aspectRatio={2} imageStyle={{ objectFit: 'cover', height: "100%" }} src="/squareBanner.png" cover />
      </Hidden>

      <CardHeader titleTypographyProps={{ variant: 'h4', textAlign: 'center' }} title="Commission Details" />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <b>Conversion Action</b>
          </Grid>
          <Grid item xs={6}>
            Online purchase with processed valid payment
          </Grid>

          <Grid item xs={6}>
            <b>Commission Type</b>
          </Grid>
          <Grid item xs={6}>
            Percent of Sale
          </Grid>

          <Grid item xs={6}>
            <b>Base Commission</b>
          </Grid>
          <Grid item xs={6}>
            20%
          </Grid>

          <Grid item xs={6}>
            <b>Commission Payout </b>
          </Grid>
          <Grid item xs={6}>
            Last day of every month
          </Grid>

          <Grid item xs={6}>
            <b>Additional Terms</b>
          </Grid>
          <Grid item xs={6}>
            Every commission payment is 20% of the sale, not including shipping or tax. All we need is your PayPal email so you can receive your commission.
          </Grid>

        </Grid>
      </CardContent>
    </Card>

  )
}
