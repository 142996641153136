import { Dialog, DialogActions, DialogContent, TextField, Typography, Stack, Button, Box, CircularProgress } from '@mui/material';
import { forwardRef, useState } from 'react';
import * as API from '../../firebase';



export default function CodeDialog({ userInfo, updateUser, isCodeDialogOpen, setCodeDialogOpen }) {
  const [code, setCode] = useState("");
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);





  const handleCodeSubmit = (e) => {
    if (code.length < 4) return setError("Code must be at least 4 characters");
    setLoading(true)
    e.preventDefault();
    API.changeAffiliateCode(userInfo.refersion_id, userInfo.affiliate_code, null, code)
      .then(({ data }) => {
        updateUser({
          refersion: {
            conversion_triggers: [data]
          }
        });
        setCodeDialogOpen(false);
      })
      .catch(e => setError(e.message))
      .finally(setLoading);
  }



  const handleCodeChange = ({ target }) => {
    setError(false);
    const stripped = target.value.toUpperCase().split("").filter(c => (/[A-Z0-9_&]/).test(c)).join("")
    setCode(stripped);
  }


  return (
    <Dialog open={!!isCodeDialogOpen}>
      <form onSubmit={handleCodeSubmit}>
        <Box p={2} bgcolor='#42a5f5'>
          <Typography variant='h3' color='white' textAlign='center'>
            Welcome to <b>#thepack</b>, {userInfo.firstName}!
          </Typography>
        </Box>
        <DialogContent>
          <Stack spacing={2}>
            <Typography whiteSpace={'break-spaces'}>
              {"Make Your 20% Code For Friends & Family Below\n(You make this up!)"}
            </Typography>
            <TextField error={!!error} helperText={error} fullWidth label="Your friends and family code" value={code} onChange={handleCodeChange} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCodeDialogOpen(false)} >Later</Button>
          <Button disabled={code.length < 4 || error} onClick={handleCodeSubmit} variant='contained' >Submit</Button>
        </DialogActions>
      </form>
      <Dialog open={!!isLoading} PaperComponent={forwardRef((props, ref) => <CircularProgress {...{ ref, ...props }} />)} />
    </Dialog>
  )
}