import { Button, CardContent, Grid, Stack, TextField, Box } from "@mui/material";
import { Card } from "../../../components/admin";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { getUserDoc, getUser, getAffiliate, deleteAmbassadors, setScopes, resetPass, editAmbassador, editAffiliate } from "../../../firebase";

export default function SubmissionsView() {
	const { ambassadorId } = useParams();
	const [user, setUser] = useState();
	const [clickCount, setClickCount] = useState(0);
	const [affiliate, setAffiliate] = useState();
	const [doc, setDoc] = useState();
	const [passResetSent, setPassResetSent] = useState(false);
	const nav = useNavigate();
	const [mutated, setMutated] = useState(false);
	const [refersionMutated, setRefersionMutated] = useState(false);

	useEffect(() => {
		getUser(ambassadorId).then(setUser).catch(console.error);
		getUserDoc(ambassadorId).then(setDoc).catch(console.error);
	}, [ambassadorId]);

	useEffect(() => {
		const f = () => "";
		if (mutated || refersionMutated) {
			window.addEventListener("beforeunload", f);
		}
		return () => window.removeEventListener("beforeunload", f);
	}, [refersionMutated, mutated]);

	useEffect(() => {
		if (doc && doc?.refersion_id?.length === 7 && !affiliate) {
			getAffiliate(doc.refersion_id)
				.then((a) => {
					if (!doc.affiliate_code) {
						setDoc((d) => ({ ...d, affiliate_code: a.rfsn_parameter.match(/rfsn=(.*)\.(.*)$/)[2] }));
					}
					if (!a.paypal_email) {
						setRefersionMutated(true);
						a.paypal_email = a.custom_fields.find((f) => f[0] === "69473")?.[2];
					}
					setAffiliate(a);
				})
				.catch(console.error);
		}
	}, [doc, affiliate]);

	const handleDelete = () => {
		if (window.confirm("Delete ambassador from BAP database?\n(this does not delete from refersion, best to just disable the account instead and remove the conversion trigger)")) {
			deleteAmbassadors([ambassadorId])
				.then(() => nav("/admin/ambassadors"))
				.catch(console.error);
		}
	};

	useEffect(() => {
		if (clickCount > 5) {
			const scopes = window.prompt("New Scopes:", user?.customClaims?.join(","));
			if (scopes) {
				setScopes(user.uid, scopes.split(","));
				setClickCount(0);
			}
		}
	}, [clickCount, user]);

	const handleResetPass = () => {
		resetPass(user.email)
			.then(() => setPassResetSent(true))
			.catch((e) => window.alert(JSON.stringify(e)));
	};

	const handleSave = () => {
		editAmbassador(doc)
			.then(() => setMutated(false))
			.catch((e) => console.error(e) || window.alert(JSON.stringify(e)));
		if (refersionMutated) {
			editAffiliate(doc.refersion_id, { paypal_email: affiliate.paypal_email })
				.then((a) => console.log(a) || setRefersionMutated(false))
				.catch((e) => console.error(e) || window.alert(JSON.stringify(e)));
		}
	};

	const handleDocChange =
		(key) =>
		({ target }) => {
			setDoc((doc) => ({
				...doc,
				[key]: target.value,
			}));
			setMutated(true);
		};

	const handlePetChange =
		(pet, key) =>
		({ target }) => {
			setDoc((d) => {
				pet[key] = target.value;
				return { ...d };
			});
			setMutated(true);
		};

	const handleRefersionChange =
		(key) =>
		({ target }) => {
			setAffiliate((a) => ({ ...a, [key]: target.value }));
		};

	return (
		<Box m={1}>
			<Grid container spacing={1}>
				<Grid container direction="column" rowSpacing={1} item xs={12} md={8}>
					<Grid item>
						<Card title="Ambassador" isLoading={!doc}>
							<CardContent>
								<Stack spacing={1}>
									<TextField disabled label="Email" value={doc?.email || ""} fullWidth />
									<TextField label="First Name" value={doc?.firstName || ""} fullWidth onChange={handleDocChange("firstName")} />
									<TextField label="Last Name" value={doc?.lastName || ""} fullWidth onChange={handleDocChange("lastName")} />
									<TextField label="Phone Number" value={doc?.phoneNumber || ""} fullWidth onChange={handleDocChange("phoneNumber")} />
								</Stack>
							</CardContent>
						</Card>
					</Grid>

					<Grid item>
						<Card title="Refersion" titleSx={{ color: !doc?.refersion_id && "red" }} isLoading={!affiliate?.email && doc?.refersion_id}>
							<CardContent>
								<Stack spacing={1}>
									<Stack direction="row">
										<TextField label="Refersion ID" value={doc?.refersion_id || ""} fullWidth onChange={handleDocChange("refersion_id")} />
										<TextField disabled label="Affiliate Code" value={doc?.affiliate_code || ""} fullWidth onChange={handleDocChange("affiliate_code")} />
									</Stack>
									{affiliate && (
										<>
											<TextField label="Paypal Email" value={affiliate.paypal_email || ""} fullWidth onChange={handleRefersionChange("paypal_email")} />
											<TextField disabled label="Conversion Trigger" value={affiliate.conversion_triggers?.[0]?.trigger || ""} fullWidth />
											<TextField disabled label="Number of Conversions" value={affiliate.conversions?.length || "0"} fullWidth />
											<TextField disabled label="Number of Payments" value={affiliate.payments?.length || "0"} fullWidth />
										</>
									)}
								</Stack>
							</CardContent>
						</Card>
					</Grid>

					<Grid item>
						<Card title="Instagram">
							<CardContent>
								<Stack spacing={1}>
									<TextField label="Handle" value={doc?.igHandle || ""} fullWidth onChange={handleDocChange("igHandle")} />
								</Stack>
							</CardContent>
						</Card>
					</Grid>

					<Grid item>
						<Card title="Models">
							<CardContent>
								<Stack spacing={1}>
									{doc?.pets?.map((pet) => (
										<Box>
											<Stack spacing={1}>
												<TextField label="Name" value={pet.name || ""} fullWidth onChange={handlePetChange(pet, "name")} />
												<TextField label="Bday" value={pet.bday || ""} fullWidth onChange={handlePetChange(pet, "bday")} />
												<TextField label="Breed" value={pet.breed || ""} fullWidth onChange={handlePetChange(pet, "breed")} />
											</Stack>
										</Box>
									))}
								</Stack>
							</CardContent>
						</Card>
					</Grid>
				</Grid>

				<Grid container item direction="column" xs={12} md={4} rowSpacing={1}>
					<Grid item>
						<Card title="Info">
							<CardContent>
								<Stack spacing={1}>
									<TextField disabled label="User ID" value={doc?.id || ""} fullWidth onClick={() => setClickCount((c) => c + 1)} />
									<TextField disabled label="Created At" value={new Date(doc?.createdAt).toLocaleString() || ""} fullWidth />
									<TextField disabled label="Last Sign In" value={(user?.metadata?.lastSignInTime ? new Date(user?.metadata?.lastSignInTime).toLocaleString() : "Never") || ""} fullWidth />
								</Stack>
							</CardContent>
						</Card>
					</Grid>

					<Grid item>
						<Button variant="contained" fullWidth onClick={() => nav("submissions")}>
							{" "}
							Submissions
						</Button>
					</Grid>

					<Grid item>
						<Card title="Actions">
							<CardContent>
								<Stack spacing={1}>
									<Button disabled={passResetSent} fullWidth variant="contained" onClick={handleResetPass}>
										{passResetSent ? "Reset Link Sent!" : "Send Password Reset Link"}
									</Button>
									<Button disabled={!(mutated || refersionMutated)} variant="contained" onClick={handleSave}>
										Save
									</Button>
									<Button color="error" variant="contained" onClick={handleDelete}>
										Delete Ambassador
									</Button>
								</Stack>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
}
